import "./Footer.scss";
import {
  FaYoutube,
  FaLinkedinIn,
  FaTelegramPlane,
  FaGithub,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="footer-bottom">
        <div className="social-media-links">
          <a
            href="https://x.com/SiliconSquire"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaXTwitter size={24} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC3GVfpPud4-ABMDtPkUEItQ"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <FaYoutube size={24} />
          </a>
          <a
            href="https://www.linkedin.com/company/silicon-squire"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn size={24} />
          </a>
          <a
            href="https://t.me/SiliconSquire"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Telegram"
          >
            <FaTelegramPlane size={24} />
          </a>
          <a
            href="https://github.com/SiliconSquire"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Telegram"
          >
            <FaGithub size={24} />
          </a>
        </div>
        <div className="copyright-div">
          © {currentYear}{" "}
          <a
            className="silicon-squire-link"
            href="https://siliconsquire.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Silicon Squire
          </a>{" "}
          - All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
