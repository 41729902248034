import MotionHoc from "./MotionHoc";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Accessibility from "/assets/imgs/Accessibility.webp";
import Performance from "/assets/imgs/Performance.webp";
import SEO from "/assets/imgs/seo.webp";
import Best_Practices from "/assets/imgs/Best_Practices.webp";
import "./Philosophy.scss";
const PhilosophyComponent = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="services">
      <Helmet>
        <title>Our Philosophy</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Philosophy" />
      </Helmet>
      <div className="head-subheads">
        <h2
          className="services-heading"
          id="services-title"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          {t("philosophy")}
        </h2>
        <div className="services-subheading" id="services-subheading">
          <p>{t("philosophyDescription")}</p>
        </div>
      </div>
      <div className="swiperservices">
        <Swiper
          pagination-clickable="true"
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          slidesPerView={1}
          slidesPerGroup={1}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper2"
        >
          <SwiperSlide
            className="slideshow-item"
            id="slideshow-item-1"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <img
              src={Performance}
              alt="Performance icon slide show"
              className="circle-image"
              id="slideshow-item-1-image"
            />
            <p className="primary-text" id="slideshow-item-1-primary-text">
              {t("performance")}
            </p>
            <p className="secondary-text" id="slideshow-item-1-secondary-text">
              {t("performanceDescription")}
            </p>
          </SwiperSlide>
          <SwiperSlide
            className="slideshow-item"
            id="slideshow-item-2"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <img
              src={Best_Practices}
              alt="Accessibility icon for slide show"
              className="circle-image"
              id="slideshow-item-2-image"
            />
            <p className="primary-text" id="slideshow-item-2-primary-text">
              {t("accessibility")}
            </p>
            <p className="secondary-text" id="slideshow-item-2-secondary-text">
              {t("accessibilityDescription")}
            </p>
          </SwiperSlide>
          <SwiperSlide
            className="slideshow-item"
            id="slideshow-item-3"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <img
              src={Accessibility}
              alt="Best Practices for slide show"
              className="circle-image"
              id="slideshow-item-3-image"
            />
            <p className="primary-text" id="slideshow-item-3-primary-text">
              {t("bestPractices")}
            </p>
            <p className="secondary-text" id="slideshow-item-3-secondary-text">
              {t("bestPracticesDescription")}
            </p>
          </SwiperSlide>
          <SwiperSlide
            className="slideshow-item"
            id="slideshow-item-4"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
          >
            <img
              src={SEO}
              alt="SEO icon for slide show"
              className="circle-image"
              id="slideshow-item-4-image"
            />
            <p className="primary-text" id="slideshow-item-4-primary-text">
              {t("seo")}
            </p>
            <p className="secondary-text" id="slideshow-item-4-secondary-text">
              {t("seoDescription")}
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

const Philosophy = MotionHoc(PhilosophyComponent);

export default Philosophy;
