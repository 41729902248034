import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer/Footer";

import Whatsapp from "/assets/imgs/wotsup.webp";
import Background1 from "/assets/imgs/newBckgrd.webp";

// Pages
import Home from "./Pages/Home";
import Technologies from "./Pages/Technologies";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services";
import ClientsSamples from "./Pages/ClientsExample";
import Philosophy from "./Pages/Philosophy";
import ContactUs from "./Pages/ContactUs";
import Pricing from "./Pages/Pricing";
import Whyus from "./Pages/Whyus";
import Analyze from "./Pages/Analyze";
import { useTranslation } from "react-i18next";
import NotFound from "./Pages/notfound";

function App() {
  const { t, i18n } = useTranslation();
  const [showWidget, setShowWidget] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 768) {
      window.particlesJS.load(
        "particles-js",
        "/particles.json",
        function () {}
      );
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowWidget(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <HelmetProvider>
      <>
        <div className={i18n.dir() === "rtl" ? "rtl" : ""}>
          <div id="particles-js" className="particles-container"></div>{" "}
          {/* Particle Container */}
          <div className="App">
            <img src={Background1} alt="Background" className="background" />
            <Sidebar />
            <div className="contentWrapper">
              <AnimatePresence mode="wait">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/Technologies" element={<Technologies />} />
                  <Route path="/ContactUs" element={<ContactUs />} />
                  <Route path="/AboutUs" element={<AboutUs />} />
                  <Route path="/ClientsSamples" element={<ClientsSamples />} />
                  <Route path="/Services" element={<Services />} />
                  <Route path="/Analyze" element={<Analyze />} />
                  <Route path="/Philosophy" element={<Philosophy />} />
                  <Route path="/Pricing" element={<Pricing />} />
                  <Route path="/Whyus" element={<Whyus />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AnimatePresence>
            </div>
            <Footer />
            {showWidget && (
              <div
                id="whatsapp-floating-widget"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
              >
                <a
                  href="https://wa.me/message/NSGEG4K5EQWIM1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={Whatsapp}
                    alt={t("whatsappAlt")}
                    style={i18n.language === "ar" ? { float: "left" } : {}}
                  />
                  <div className="widget-text" id="whatsapp-widget">
                    {t("chatWithExpert")}
                    <br />
                    {t("noCharges")}
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    </HelmetProvider>
  );
}

export default App;
