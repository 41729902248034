import { useState } from "react";
import MotionHoc from "./MotionHoc";
import "./Anaylze.scss";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
//import { jsPDF } from "jspdf";
const AnaylzeComponent = () => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [scores, setScores] = useState(null);
  const { t, i18n } = useTranslation();
  // const [report, setReport] = useState(null);
  const getScores = () => {
    setLoading(true);

    fetch(
      `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${url}&category=performance&category=accessibility&category=best-practices&category=seo&key=AIzaSyCCQ0AG5Ln7QZ570RE4O-IqwkgGOgj-Pjw`
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);

        if (data.lighthouseResult && data.lighthouseResult.categories) {
          setScores({
            performance: data.lighthouseResult.categories.performance
              ? parseInt(
                  data.lighthouseResult.categories.performance.score * 100
                ) + "%"
              : "N/A",
            accessibility: data.lighthouseResult.categories.accessibility
              ? parseInt(
                  data.lighthouseResult.categories.accessibility.score * 100
                ) + "%"
              : "N/A",
            bestPractices: data.lighthouseResult.categories["best-practices"]
              ? parseInt(
                  data.lighthouseResult.categories["best-practices"].score * 100
                ) + "%"
              : "N/A",
            seo: data.lighthouseResult.categories.seo
              ? parseInt(data.lighthouseResult.categories.seo.score * 100) + "%"
              : "N/A",
          });
          // setReport(data.lighthouseResult);
        } else {
          console.error("Invalid response from API", data);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // const generateReport = () => {
  //   const doc = new jsPDF();

  //   doc.text("Performance: " + scores.performance, 10, 10);
  //   doc.text("Accessibility: " + scores.accessibility, 10, 20);
  //   doc.text("Best Practices: " + scores.bestPractices, 10, 30);
  //   doc.text("SEO: " + scores.seo, 10, 40);
  //   doc.text("Overall Score: " + report.categories.performance.score, 10, 50);
  //   doc.text(
  //     "First Contentful Paint: " +
  //       report.audits["first-contentful-paint"].displayValue,
  //     10,
  //     60
  //   );
  //   doc.text(
  //     "Speed Index: " + report.audits["speed-index"].displayValue,
  //     10,
  //     70
  //   );
  //   doc.text(
  //     "Time to Interactive: " + report.audits.interactive.displayValue,
  //     10,
  //     80
  //   );
  //   doc.text(
  //     "Total Blocking Time: " +
  //       report.audits["total-blocking-time"].displayValue,
  //     10,
  //     90
  //   );
  //   doc.text(
  //     "Largest Contentful Paint: " +
  //       report.audits["largest-contentful-paint"].displayValue,
  //     10,
  //     100
  //   );
  //   doc.text(
  //     "Cumulative Layout Shift: " +
  //       report.audits["cumulative-layout-shift"].displayValue,
  //     10,
  //     110
  //   );

  //   doc.save("report.pdf");
  // };

  return (
    <div className="analyze">
      <Helmet>
        <title>Analyze your website</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Analyze" />
      </Helmet>
      <div id="inputArea">
        <h2 id="analyze-h2">{t("analyzeHeading")}</h2>
        <div className="arrows">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <input
          type="url"
          id="urlInput"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder={t("analyzeInputPlaceholder")}
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        />
        <button id="analyze-button" onClick={getScores}>
          {t("analyzeButton")}
        </button>
      </div>

      {loading && (
        <div id="loading-anaylze" className="multi-spinner-container">
          <div className="multi-spinner">
            <div className="multi-spinner">
              <div className="multi-spinner">
                <div className="multi-spinner">
                  <div className="multi-spinner">
                    <div className="multi-spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {scores && (
        <div id="scores">
          <h2 id="scores-h2">{t("scoresHeading")}</h2>
          <div className="score-container">
            <div className="score-item">
              <span>{scores.performance}</span>
              <p>{t("performanceLabel")}</p>
            </div>
            <div className="score-item">
              <span>{scores.accessibility}</span>
              <p>{t("accessibilityLabel")}</p>
            </div>
            <div className="score-item">
              <span>{scores.bestPractices}</span>
              <p>{t("bestPracticesLabel")}</p>
            </div>
            <div className="score-item">
              <span>{scores.seo}</span>
              <p>{t("seoLabel")}</p>
            </div>
          </div>
          {/* {report && (
            <button id="download-analyze" onClick={generateReport}>
              {t("downloadanalyze")}
            </button>
          )} */}
        </div>
      )}
    </div>
  );
};

const Anaylze = MotionHoc(AnaylzeComponent);

export default Anaylze;
