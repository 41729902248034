import MotionHoc from "./MotionHoc";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import "./Pricing.scss";
import GistIcon from "/assets/imgs/gist.png";
import Zain from "/assets/imgs/zcash.png";
import Qicard from "/assets/imgs/qi.png";
import { useTranslation } from "react-i18next";

const PricingComponent = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const { t, i18n } = useTranslation();

  const handleSlideChange = (slideIndex) => {
    console.log("Slide change requested to:", slideIndex);
    setActiveSlide(slideIndex);
    if (swiperInstance) {
      swiperInstance.slideTo(slideIndex);
    } else {
      console.error("Swiper instance not found.");
    }
  };

  return (
    <div className="pricingz">
      <Helmet>
        <title>Pricings</title>
        <meta
          name="description"
          content="Professionals in web design, graphics, SEO, and more. Explore our work and join us in crafting exceptional digital experiences."
        />
        <link rel="canonical" href="https://siliconsquire.com/Pricing" />
      </Helmet>
      <section id="swiper-section">
        <div className="pricings-swiper">
          <Swiper
            effect="cards"
            grabCursor={true}
            onSwiper={setSwiperInstance}
            onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
            className="mySwiper"
            modules={[EffectCards]}
            initialSlide={activeSlide}
          >
            <SwiperSlide>
              <div className="pricing-card">
                <h3 dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  {t("websitedesign")}
                </h3>
                <ul>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("websitedesignitem1")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("websitedesignitem2")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("websitedesignitem3")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("websitedesignitem4")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("websitedesignitem5")}
                  </li>
                </ul>
                <span
                  className="price"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("startingprice")}
                </span>
                <img
                  src={GistIcon}
                  alt="Gesture swipe"
                  className="gesture-icon"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="pricing-card">
                <h3 dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  {t("branding")}
                </h3>
                <ul>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("brandingitem1")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("brandingitem2")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("brandingitem3")}
                  </li>
                </ul>
                <span
                  className="price"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("startingprice2")}
                </span>
                <img
                  src={GistIcon}
                  alt="Gesture swipe"
                  className="gesture-icon"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="pricing-card">
                <h3
                  id="pricing-h3-3"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("seoservices")}
                </h3>
                <ul>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("seoservicesitem1")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("seoservicesitem2")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("seoservicesitem3")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("seoservicesitem4")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("seoservicesitem5")}
                  </li>
                </ul>
                <span
                  className="price"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("onetimefee")}
                </span>
                <img
                  src={GistIcon}
                  alt="Gesture swipe"
                  className="gesture-icon"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="pricing-card">
                <h3 dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  {t("backlinkpackage")}
                </h3>
                <ul>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("backlinkpackageitem1")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("backlinkpackageitem2")}
                  </li>
                </ul>
                <span
                  className="price"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("monthlyfee")}
                </span>
                <img
                  src={GistIcon}
                  alt="Gesture swipe"
                  className="gesture-icon"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="pricing-card">
                <h3 dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                  {t("bespokesolutions")}
                </h3>
                <ul>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("bespokesolutionsitem1")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("bespokesolutionsitem2")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("bespokesolutionsitem3")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("bespokesolutionsitem4")}
                  </li>
                  <li dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                    {t("bespokesolutionsitem5")}
                  </li>
                </ul>
                <span
                  className="price"
                  dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                  {t("notapplicable")}
                </span>
                <img
                  src={GistIcon}
                  alt="Gesture swipe"
                  className="gesture-icon"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="packages-names" id="Pricing">
          <h2 id="swiperh2">{t("ourpackages")}</h2>
          <ul id="swiper-nav">
            <li
              id="data-slide-1"
              data-slide="0"
              onClick={() => handleSlideChange(0)}
            >
              {t("websitedesignbutton")}
            </li>
            <li
              id="data-slide-2"
              data-slide="1"
              onClick={() => handleSlideChange(1)}
            >
              {t("brandingbutton")}
            </li>
            <li
              id="data-slide-3"
              data-slide="2"
              onClick={() => handleSlideChange(2)}
            >
              {t("seoservicesbutton")}
            </li>
            <li
              id="data-slide-4"
              data-slide="3"
              onClick={() => handleSlideChange(3)}
            >
              {t("backlinkpackagebutton")}
            </li>
            <li
              id="data-slide-5"
              data-slide="4"
              onClick={() => handleSlideChange(4)}
            >
              {t("bespokesolutionsbutton")}
            </li>
          </ul>
          <div className="payments-icons">
            <img
              src={Zain}
              alt="zain cash Payment method"
              width={100}
              height={42}
            />
            <img
              src={Qicard}
              alt="zain cash Payment method"
              width={99.5}
              height={45.5}
            />
          </div>
          <p id="payments" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            {t("paymentmethods")}
          </p>
        </div>
      </section>
    </div>
  );
};

const Pricing = MotionHoc(PricingComponent);

export default Pricing;
