import { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import Flag from "react-flagkit";
import "./Sidebar.scss";
import { useTranslation } from "react-i18next";
import logo from "../../assets/Logo.webp";
import Home from "../../assets/home.png";
import AboutUs from "../../assets/aboutus.png";
import Services from "../../assets/services.png";
import ContactUs from "../../assets/contactus.png";
import Philosophy from "../../assets/philo.png";
import Pricing from "../../assets/price.png";
import Tech from "../../assets/tech.png";
import Whyus from "../../assets/whyus.png";
import blogs from "../../assets/blog.png";
import Analyze from "../../assets/Analyze.png";
import Bloodsample from "../../assets/blood-sample.png";
import scrollIndicator from "../../assets/scroll-indicator.png";

const languages = [
  { code: "en", countryCode: "US", name: "EN" },
  { code: "ar", countryCode: "SA", name: "ع" },
  { code: "ru", countryCode: "RU", name: "RUS" },
];

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = useCallback(() => {
    setSidebarExpanded((prev) => !prev);
  }, []);

  const handleSidebarHover = useCallback(() => {
    if (window.innerWidth > 768) setSidebarExpanded(true);
  }, []);

  const handleSidebarLeave = useCallback(() => {
    if (window.innerWidth > 768) setSidebarExpanded(false);
  }, []);

  // Simplified language change function
  const changeLanguage = useCallback(
    (code) => {
      i18n.changeLanguage(code); // Directly change the language
      setSelectedLanguage(code); // Update state
      setIsOpen(false); // Close the language selector
    },
    [i18n]
  );

  const adjustTransparency = useCallback((index) => {
    const items = document.querySelectorAll(".slickBar .item");
    items.forEach((item, i) => {
      item.classList.toggle("active", i === index);
      item.classList.toggle("adjacent", i === index - 1 || i === index + 1);
    });
  }, []);

  const withMobileSidebarClose = useCallback((originalOnClick) => {
    return (e) => {
      if (window.innerWidth <= 768) setSidebarExpanded(false);
      if (originalOnClick) originalOnClick(e);
    };
  }, []);

  const handleScrollToggle = useCallback(() => {
    const sidebarItems = sidebarRef.current.querySelectorAll("li");
    if (!isScrolledToBottom) {
      sidebarItems[sidebarItems.length - 1].scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else {
      sidebarItems[0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setIsScrolledToBottom((prev) => !prev);
  }, [isScrolledToBottom]);

  useEffect(() => {
    adjustTransparency(1);
  }, [adjustTransparency]);

  return (
    <div
      className={`container ${
        isSidebarExpanded ? "sidebar-expanded expanded" : ""
      }`}
      onMouseEnter={handleSidebarHover}
      onMouseLeave={handleSidebarLeave}
    >
      <button
        className={`button ${isSidebarExpanded ? "clicked" : ""}`}
        onClick={toggleSidebar}
        aria-label="Toggle Menu Navigation menu"
      />
      <div className="sidebarContainer">
        <div className="logo">
          <img
            className="logosmall"
            width={56}
            height={60}
            src={logo}
            alt="logo"
          />
          <div
            className={`language-selector ${isOpen ? "open" : ""}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="selected-language">
              <Flag
                country={
                  languages.find((lang) => lang.code === selectedLanguage)
                    ?.countryCode || "US"
                }
              />
              <span className={` ${isOpen ? "open" : ""}`} />
            </div>
            <div className="language-dropdown">
              {languages.map((lang) => (
                <div
                  key={lang.code}
                  className={`language-option ${
                    lang.code === selectedLanguage ? "selected" : ""
                  }`}
                  onClick={() => changeLanguage(lang.code)}
                >
                  <Flag country={lang.countryCode} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <ul
          ref={sidebarRef}
          className={`slickBar ${isSidebarExpanded ? "expanded clicked" : ""}`}
        >
          <li>
            <a
              href="https://blogs.siliconsquire.com"
              className="item"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width={24} height={24} src={blogs} alt="Blog website" />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("blog_sidebar")}
              </span>
            </a>
          </li>
          <li>
            <NavLink
              to="/"
              onClick={withMobileSidebarClose(() => adjustTransparency(1))}
              className="item"
            >
              <img width={24} height={24} src={Home} alt="Home page" />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("home_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Philosophy"
              onClick={withMobileSidebarClose(() => adjustTransparency(2))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={Philosophy}
                alt="Philosophy page"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("Philosophy_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Services"
              onClick={withMobileSidebarClose(() => adjustTransparency(3))}
              className="item"
            >
              <img width={24} height={24} src={Services} alt="Services page" />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("services_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ClientsSamples"
              onClick={withMobileSidebarClose(() => adjustTransparency(4))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={Bloodsample}
                alt="Clients Samples page"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("clients_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Analyze"
              onClick={withMobileSidebarClose(() => adjustTransparency(5))}
              className="item"
            >
              <img width={24} height={24} src={Analyze} alt="Analyze page" />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("analyze_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Pricing"
              onClick={withMobileSidebarClose(() => adjustTransparency(6))}
              className="item"
            >
              <img width={24} height={24} src={Pricing} alt="Pricings Page" />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("pricings_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Technologies"
              onClick={withMobileSidebarClose(() => adjustTransparency(7))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={Tech}
                alt="Technologies used Page"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("technologies_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ContactUs"
              onClick={withMobileSidebarClose(() => adjustTransparency(8))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={ContactUs}
                alt="Contact Us form and info"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("contact_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Whyus"
              onClick={withMobileSidebarClose(() => adjustTransparency(9))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={Whyus}
                alt="Why choose us page"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("whyus_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/AboutUs"
              onClick={withMobileSidebarClose(() => adjustTransparency(10))}
              className="item"
            >
              <img
                width={24}
                height={24}
                src={AboutUs}
                alt="About Us description page"
              />
              <span className={`text ${isSidebarExpanded ? "visible" : ""}`}>
                {t("about_sidebar")}
              </span>
            </NavLink>
          </li>
          <li>
            <img
              className={`Scrollable ${isScrolledToBottom ? "flipped" : ""}`}
              width={40}
              height={40}
              src={scrollIndicator}
              alt="Scrollable Content"
              onClick={handleScrollToggle}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
